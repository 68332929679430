<template>
  <div class="profile">
    <div class="container">
      <div class="profile__title">
        <h3>{{$t('store.profile_management')}}</h3>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('store.account_management')}}</div>
        <div class="title-red mg-b-5">{{$t('store.joined_on')}}</div>
        <div class="text-dark mg-b-15">{{$filters.formatTimestamp(store.user.created_at)}}</div>
        <div class="title-red mg-b-5">{{$t('store.email')}}</div>
        <div class="text-dark mg-b-15">{{store.user.email}}</div>
        <div class="title-red mg-b-5">{{$t('store.status')}}</div>
        <div class="text-dark mg-b-15">{{store.status === 3 ? $t('global.pending') : (store.status === 2 ? $t('global.approved') : $t('global.rejected'))}}</div>

        <div class="title-red mg-b-5">{{$t('store.business_type')}}</div>
        <div class="text-dark mg-b-15">{{$t(businessTypeText(store.business_type))}}</div>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('store.tell_us_contact')}}</div>
        <div class="title-red mg-b-10">{{$t('store.business_representative')}}</div>
        <business-contact v-model="store.representative" :read-only="true"/>

        <div v-if="store.business_type === types.business.CORPORATION || store.business_type === types.business.PARTNERSHIP">
          <div class="title-red mg-b-5 mg-t-30">{{$t('store.business_owners')}}</div>
          <business-contact
            v-for="(item, index) in store.owners"
            :key="index"
            :has-title="true"
            :title="$t('store.owner') + ' ' + (index + 1)"
            :read-only="true"
            :has-equity="true"
            v-model="store.owners[index]"/>
        </div>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-15">{{$t('store.venue_about')}}</div>
        <div class="text-dark mg-b-20">{{$t('store.venue_about_sub')}}</div>
        <div v-if="store.business_type !== types.business.INDIVIDUAL">
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.registration_no')}}</div>
          <div class="text-dark">{{store.reg_number}}</div>
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.company_number')}}</div>
          <div class="text-dark">{{store.company_number}}</div>
        </div>
        <div class="title-gray mg-b-5 mg-t-10">{{$t('store.industry')}}</div>
        <div class="text-dark">{{store.industry}}</div>
        <div v-if="store.business_type !== types.business.INDIVIDUAL">
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.company_name_legal')}}</div>
          <div class="flex align-start justify-between mg-b-15 form-element">
            <div class="flex-50">
              <span>{{$t('store.english_name')}}</span>
              <div class="text-dark">{{store.company_legal}}</div>
            </div>
            <div class="flex-50">
              <span>{{$t('store.chinese_name')}}</span>
              <div class="text-dark">{{store.company_legal_c}}</div>
            </div>
          </div>
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.company_name')}}</div>
          <div class="flex align-start justify-between mg-b-15 form-element">
            <div class="flex-50">
              <span>{{$t('store.english_name')}}</span>
              <div class="text-dark">{{store.company}}</div>
            </div>
            <div class="flex-50">
              <span>{{$t('store.chinese_name')}}</span>
              <div class="text-dark">{{store.company_c}}</div>
            </div>
          </div>
        </div>
        <div class="title-gray mg-b-5 mg-t-10">{{$t('store.website')}}</div>
        <div class="text-dark">{{store.website || '-'}}</div>
        <div>
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.company_phone')}}</div>
          <div class="text-dark">{{store.company_phone || '-'}}</div>
          <div class="title-gray mg-b-5 mg-t-10">{{$t('store.address')}}</div>
          <div class="flex align-start justify-between mg-b-15 form-element">
            <div class="flex-50">
              <span>{{$t('store.english_name')}}</span>
              <div class="text-dark">{{store.building}}</div>
              <div class="text-dark">{{store.road}}</div>
              <div class="text-dark">{{store.region.name}}</div>
              <div class="text-dark">{{store.district.name}}</div>
            </div>
            <div class="flex-50">
              <span>{{$t('store.chinese_name')}}</span>
              <div class="text-dark">{{store.building_c}}</div>
              <div class="text-dark">{{store.road_c}}</div>
              <div class="text-dark">{{store.region.name_c}}</div>
              <div class="text-dark">{{store.district.name_c}}</div>
            </div>
          </div>
          <div class="mg-t-20 mg-b-20 profile__map" ref="map"/>
        </div>

        <div class="title-gray mg-b-10">{{$t('store.hours_of_op')}}</div>
        <div class="flex align-center justify-start">
          <div class="flex-20"/>
          <div class="flex-20 title-red">{{$t('store.open')}}</div>
          <div class="flex-20"/>
          <div class="flex-20 title-red">{{$t('store.close')}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.monday')}}</div>
          <div class="flex-20 light">{{store.mon_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.mon_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.tuesday')}}</div>
          <div class="flex-20 light">{{store.tue_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.tue_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.wednesday')}}</div>
          <div class="flex-20 light">{{store.wed_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.wed_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.thursday')}}</div>
          <div class="flex-20 light">{{store.thu_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.thu_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.friday')}}</div>
          <div class="flex-20 light">{{store.fri_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.fri_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.saturday')}}</div>
          <div class="flex-20 light">{{store.sat_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.sat_close || '-'}}</div>
        </div>
        <div class="flex align-center justify-start">
          <div class="flex-20">{{$t('store.sunday')}}</div>
          <div class="flex-20 light">{{store.sun_open || '-'}}</div>
          <div class="flex-20 dots"></div>
          <div class="flex-20 light">{{store.sun_close || '-'}}</div>
        </div>
        <div class="title-gray mg-b-10 mg-t-20">{{$t('store.description')}}</div>
        <div class="text-dark" v-html="htmlFilter(store.description)"/>
        <div class="title-gray mg-b-10 mg-t-20">{{$t('store.description_c')}}</div>
        <div class="text-dark" v-html="htmlFilter(store.description_c)"/>
        <div class="title-gray mg-b-5 mg-t-20">{{$t('store.what_service')}}</div>
        <div class="text-dark mg-b-20">{{store.service.name}}</div>
        <div class="title-gray mg-b-15">{{$t('store.what_features')}}</div>

        <div v-for="(service, index) in services" :key="index" class="flex align-start justify-start mg-b-5" :class="{ hidden: service.id !== store.service.id || !service.selected || !service.selected.length }">
          <div class="flex-20 text-dark bold">{{service.name}}</div>
          <div class="flex-80 flex flex-wrap align-start justify-start">
            <div v-for="(feature, i) in service.features" :key="i" :class="store.features.some(e => e.id === feature.id) ? 'tag selected' : 'tag' ">{{feature.name}}</div>
          </div>
        </div>

        <div class="title-gray mg-t-20 mg-b-5">{{$t('store.other_features')}}</div>
        <div class="text-dark">{{store.feature_description}}</div>
        <div class="text-dark">{{store.feature_description_c}}</div>

        <div class="title-gray mg-t-20">{{$t('store.profile_pic')}}</div>
        <div class="subtitle-gray mg-b-10">{{$t('store.profile_pic_sub')}}</div>
        <div class="flex align-start flex-wrap justify-start">
          <img :src="store.avatar" class="uploaded-img"/>
        </div>

        <div class="title-gray mg-t-20">{{$t('store.other_pics')}}</div>
        <div class="subtitle-gray mg-b-10">{{$t('store.other_pics_sub')}}</div>
        <div class="flex align-start flex-wrap justify-start">
          <img v-for="(image, index) in store.images" :key="index" :src="image.link" class="uploaded-img"/>
        </div>

        <div class="title-red mg-t-20 mg-b-10">{{$t('store.payment_title')}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_name')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_name || '-'}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_branch')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_branch || '-'}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_account_name')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_account_name || '-'}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_routing')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_routing || '-'}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_account_number')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_account_number || '-'}}</div>
        <div class="title-gray mg-t-5">{{$t('store.bank_currency')}}</div>
        <div class="text-dark mg-b-20">{{store.bank_currency || '-'}}</div>
      </div>
      <div class="box mg-b-10">
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_edit_date')}}</div>
        <div class="text-dark">{{store.admin_updated_at ? $filters.formatTimestamp(store.admin_updated_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_edit_email')}}</div>
        <div class="text-dark">{{store.admin_updated_by ? store.admin_updated_by.email : '-'}}</div>
      </div>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import { Settings, Store } from '@/api/api'
import { businessTypeText, types } from '@/config/config'
import BusinessContact from '@/components/businessContact/businessContact'

export default {
  name: 'Profile',
  components: {
    Loading,
    BusinessContact
  },
  data () {
    return {
      isLoading: true,
      storeId: 0,
      services: [],
      store: {
        user: {},
        representative: {},
        owners: [],
        images: [],
        features: [],
        service: {},
        region: {},
        district: {}
      }
    }
  },
  async mounted () {
    await this.loadGmaps()

    if (this.$route.params.id) {
      this.storeId = this.$route.params.id

      this.loadProduct()
    }
  },
  computed: {
    types () {
      return types
    }
  },
  methods: {
    loadGmaps () {
      return new Promise((resolve, reject) => {
        if (window.google) {
          resolve(true)
        }

        const script = document.createElement('script')
        script.onload = () => {
          resolve(true)
        }
        script.async = true
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKQUhF7BmkTk3iAqd4KLAD0kmFjG3HcaY&libraries=&v=weekly'
        document.head.appendChild(script)
      })
    },
    initMap (lat, long) {
      lat = parseFloat(lat)
      long = parseFloat(long)

      if (this.$refs.map) {
        this.map = new window.google.maps.Map(this.$refs.map, {
          zoom: 14,
          center: { lat: lat, lng: long }
        })

        this.marker && this.marker.setMap(null)
        this.marker = new window.google.maps.Marker({
          map: this.map,
          position: { lat: lat, lng: long }
        })
      }
    },
    htmlFilter (html) {
      html = html || '-'

      return html.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    async loadProduct () {
      this.isLoading = true

      try {
        const [merchant, services] = await Promise.all([
          Store.getMine(this.storeId),
          Settings.getServices()
        ])

        services.data = services.data.filter(service => service.is_web_active == true)

        if (!merchant.data.id) {
          merchant.data = this.store
        }

        const service = services.data.find((s) => s.id === merchant.data.service.id)

        merchant.data.service_name = service ? service.name : '-'
        merchant.data.features.forEach((f) => {
          const serviceIndex = services.data.findIndex((s) => s.id === f.service_id)

          services.data[serviceIndex].selected = services.data.selected || []
          services.data[serviceIndex].selected.push(f)
        })

        this.store = merchant.data
        this.services = services.data

        this.initMap(this.store.lat || 0, this.store.lng || 0)
      } catch (err) {
        this.$toast.error(this.$t('store.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    businessTypeText (val) {
      return val ? businessTypeText[val] : '-'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
